/* TODO: Convert to SCSS or SASS */




/* @import "./spinner.sass"; */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* $dark_pageBackgroundColor: rgba(0,0,0,1);
$base_pageBackgroundColor: rgba(15,15,15,1);
$light_pageBackgroundColor: rgba(30,30,30,1);
$xlight_pageBackgroundColor: rgba(45,45,45,1);

$dark_halfOpacity_pageBackgroundColor: rgba(0,0,0,0.5);
$base_halfOpacity_pageBackgroundColor: rgba(15,15,15,0.5);
$light_halfOpacity_pageBackgroundColor: rgba(30,30,30,0.5);

$dark_fontColor: rgba(225,225,225,1);
$base_fontColor: rgba(240,240,240,1);
$light_fontColor: rgba(255,255,255,1);

$dark_halfOpacity_fontColor: rgba(225,225,225,0.5);
$base_halfOpacity_fontColor: rgba(240,240,240,0.5);
$light_halfOpacity_fontColor: rgba(255,255,255,0.5);

$dark_quarterOpacity_fontColor: rgba(225,225,225,0.25);
$base_quarterOpacity_fontColor: rgba(240,240,240,0.25);
$light_quarterOpacity_fontColor: rgba(255,255,255,0.25);

$inactive_fontColor: rgba(100,100,100,1);

$dark_primaryColor: rgba(255,0,127,1); // TODO: Update
$base_primaryColor: rgba(255,0,127,1);
$light_primaryColor: rgba(255,0,127,1); // TODO: Update

$dark_secondaryColor: rgba(96,13,255,1); // TODO: Update
$base_secondaryColor: rgba(96,13,255,1); // TODO: Update
$light_secondaryColor: rgba(96,13,255,1); // TODO: Update

$dark_tertiaryColor: rgba(73,220,177,1); // TODO: Update
$base_tertiaryColor: rgba(73,220,177,1); // TODO: Update
$light_tertiaryColor: rgba(73,220,177,1); // TODO: Update

$base_primaryGradientStart: rgba(255,0,69,1);
$base_primaryGradientEnd: rgba(255,0,255,1);
$base_gradientAngle: 135deg;

$base_blurAmount: 15px;

$base_margin: 25px;
$base_borderRadius: 25px;

$base_natHACKSTitleLetterSize: 13vw; */

* {
  box-sizing: border-box;
}

/* html {
  // scroll-behavior: smooth; // Currently breaks the scroller, feedback loop needs it to jump.
  // background-color: $base_pageBackgroundColor; // This fixes the issue with overscroll looking bad on safari... BUT it hides all components with negative z index.
} */

body {
  /* background-color: $base_pageBackgroundColor;
  color: $base_fontColor; */
  background-color: rgba(15,15,15,1);
  color: rgba(240,240,240,1);

  margin: 0;

  /* No horizontal scrolling */
  overflow-x: hidden;

  font-size: 12px;
  font-weight: 400;
  font-family: 'Montserrat', sans-serif;
}


.main {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row-reverse;
}

.content_container {
  /* background-color: rgba(255,0,0,0.5); */
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.nav_container {
  height: 100vh;
  padding: 30px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  position: fixed;
}


.nav_element {
  height: 25px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  text-decoration: none;

  font-weight: 200;
  font-size: 20px;

  color: rgba(100,100,100);
}

.nav_element_active {
  font-weight: 400;
  color: rgba(240,240,240);
}

.nav_element:hover {
  background: -webkit-linear-gradient(45deg, #FF007A, #AD00FF);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.hero_container {
  width: 100vw;
  height: 100vh;
  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  /* background-color: rgba(255,0,255,0.5); */
}

.hero_title {
  font-weight: 900;
  font-size: 100px;
}

.hero_subtitle {
  font-weight: 200;
  font-size: 50px;

  background: -webkit-linear-gradient(45deg, #FF007A, #AD00FF);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}





/* Temp */
li {
  user-select: none;
}

.placeholder {
  height: 100vh;
  padding: 30px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  /* background-color: rgba(255,0,0,0.1); */
}